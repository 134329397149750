// CHakra
import { Button, Center, Heading, Image, Text } from "@chakra-ui/react";
import { useRouter } from "next/router";
import React from "react";

const NotFound = () => {
  const router = useRouter();

  return (
    <Center h="100vh" flexDir="column" textAlign="center">
      <Image src="/Errors/404.svg" />
      <Heading as="h1" color="blue.500" fontSize="3xl" mt={6} fontWeight={900}>
        Page not found
      </Heading>

      <Text mt={1}>We’re sorry the page you’re trying to load couldn’t be found.</Text>

      <Button mt={6} fontSize="sm" size="lg" px={6} onClick={() => router.back()} fontWeight={700} colorScheme="blue" borderRadius="lg">
        Go Back
      </Button>
    </Center>
  );
};

export default NotFound;
